import axios from 'axios';

const baseURL =
  process.env.REACT_APP_AXIOM_ENV === "production"
    ? process.env.REACT_APP_AXIOM_URL
    : "http://localhost:3000"; // Local dev server

const API = axios.create({ baseURL });

// Automatically add token to requests if available
API.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export const deleteTask = async (taskId) => {
  try {
    const response = await API.delete(`/task/${taskId}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || "Failed to delete task.";
  }
};

export default API;