import React, { useState, useEffect } from "react";
import { Typography, Button, Container, TextField, Alert, Box } from "@mui/material";
import API from "../api";

const Dashboard = () => {
  const [task, setTask] = useState(null); // Current task
  const [newTask, setNewTask] = useState(""); // Input for new task
  const [tips, setTips] = useState(null); // Array of tips
  const [suggestions, setSuggestions] = useState(""); // AI suggestions
  const [error, setError] = useState(""); // Error messages
  const [currentIndex, setCurrentIndex] = useState(0); // Tip carousel index

  // Fetch the current task
  const fetchTask = async () => {
    try {
      const response = await API.get("/task/");
      setTask(response.data);

      // Parse the solution field and extract tips
      const parsedSolution = JSON.parse(response.data.solution);
      const extractedTips = Object.values(parsedSolution.tips);

      setTips(extractedTips);
    } catch (error) {
      console.error(error);
      setTask(null);
      setTips(null);
    }
  };

  // Add a new task
  const addTask = async () => {
    try {
      await API.post("/task/add", { task: newTask });
      setNewTask("");
      fetchTask(); // Reload the current task
    } catch {
      setError("Failed to add task.");
    }
  };

  // Delete the current task
  const deleteTask = async () => {
    if (window.confirm("Are you sure you want to delete this task?")) {
      try {
        await API.delete(`/task/${task.id}`);
        setTask(null); // Remove the task from the UI
        setTips(null); // Clear tips
      } catch {
        setError("Failed to delete task.");
      }
    }
  };

  // Handle carousel navigation
  const handleNext = () => {
    if (tips) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % tips.length);
    }
  };

  const handlePrev = () => {
    if (tips) {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? tips.length - 1 : prevIndex - 1
      );
    }
  };

  useEffect(() => {
    fetchTask();
  }, []);

  return (
    <Container maxWidth="sm" style={{ marginTop: "50px" }}>
      <img className="profile-photo" src="/doonejob.jpg" alt={"Do One Job"} width="100%" />

      {error && <Alert severity="error" style={{ marginBottom: "20px" }}>{error}</Alert>}

      {task ? (
        <>
          <Typography variant="h6">Today's Job:</Typography>
          <Typography variant="body1" style={{ marginBottom: "20px" }}>
            {task.task}
          </Typography>

          <Button
            variant="outlined"
            color="secondary"
            onClick={deleteTask}
            style={{ marginBottom: "20px" }}
          >
            Complete Task
          </Button>

          {/* Tips Carousel */}
          <Container maxWidth="sm" style={{ marginTop: "50px" }}>
            <Typography variant="h4" gutterBottom>
              Tips
            </Typography>
            <Box
              style={{
                border: "1px solid #ccc",
                borderRadius: "8px",
                padding: "20px",
                textAlign: "center",
              }}
            >
              <Typography variant="body1" style={{ marginBottom: "20px" }}>
                {tips && tips.length > 0 ? "Hour " + currentIndex + ": " + tips[currentIndex] : "No tips available."}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Button variant="outlined" onClick={handlePrev}>
                Previous Tip
              </Button>
              <Button variant="contained" onClick={handleNext}>
                Next Tip
              </Button>
            </Box>
          </Container>
        </>
      ) : (
        <div>
          <Typography variant="body1" style={{ marginBottom: "20px" }}>
            No task found. Add a new task below!
          </Typography>

          <TextField
            fullWidth
            label="New Task"
            value={newTask}
            onChange={(e) => setNewTask(e.target.value)}
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={addTask}
            style={{ marginTop: "20px" }}
          >
            Add Task
          </Button>
        </div>
      )}
    </Container>
  );
};

export default Dashboard;