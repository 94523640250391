import React, { useState } from 'react';
import { TextField, Button, Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import API from '../api';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await API.post('/auth/login', { email, password });
      localStorage.setItem('token', response.data.token);
      navigate('/dashboard');
    } catch (error) {
      alert('Login failed. Please check your credentials.');
    }
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '50px' }}>
      <Typography variant="h4" gutterBottom>Login</Typography>
      <TextField 
        fullWidth 
        label="Email" 
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
        margin="normal" 
      />
      <TextField 
        fullWidth 
        label="Password" 
        type="password" 
        value={password} 
        onChange={(e) => setPassword(e.target.value)} 
        margin="normal" 
      />
      <Button 
        variant="contained" 
        color="primary" 
        fullWidth 
        onClick={handleLogin} 
        style={{ marginTop: '20px' }}>
        Login
      </Button>
      <Typography style={{ marginTop: '20px' }}>
        Don't have an account? <a href="/signup">Sign up</a>
      </Typography>
    </Container>
  );
};

export default Login;