import React, { useState } from 'react';
import { TextField, Button, Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import API from '../api';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSignup = async () => {
    try {
      await API.post('/auth/signup', { email, password });
      alert('Signup successful! Please login.');
      navigate('/');
    } catch (error) {
      alert('Signup failed. Try another email.');
    }
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '50px' }}>
      <Typography variant="h4" gutterBottom>Signup</Typography>
      <TextField 
        fullWidth 
        label="Email" 
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
        margin="normal" 
      />
      <TextField 
        fullWidth 
        label="Password" 
        type="password" 
        value={password} 
        onChange={(e) => setPassword(e.target.value)} 
        margin="normal" 
      />
      <Button 
        variant="contained" 
        color="primary" 
        fullWidth 
        onClick={handleSignup} 
        style={{ marginTop: '20px' }}>
        Signup
      </Button>
    </Container>
  );
};

export default Signup;